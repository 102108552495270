import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Cookies from 'universal-cookie';

class LogoutView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const cookies = this.props.cookies;
    cookies.remove('loginkey', { path: '/' });
    window.location.replace('/');
  }

  render() {
    const classes = this.props.classes;
    return (
      <Page className={classes.root} title="Logout">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="lg">
            <Grid container spacing={0}>
              <Grid item lg={12}>
                <Box textAlign="center" mt={1}>
                  You are now logged out
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Page>
    );
  }
}

export default () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const loginRef = useRef(null);
  const classes = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));
  return (
    <LogoutView
      classes={classes}
      navigate={navigate}
      cookies={cookies}
      loginRef={loginRef}
    />
  );
};
