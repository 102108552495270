import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import {callFetch, getBearer} from '../../helper';

class SetPassword extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      keyValid: false,
      passwordSet: false,
      id: 0,
      key: ''
    };
  }

  
  async validKey() {
    const bearer = await getBearer();

    let request = {};
    let urls = window.location.pathname.split('/');
    request['key'] = urls[2];

    const validation = await callFetch(
      process.env.REACT_APP_API_URL+'api/sponsor/validate_email',
      'POST',
      request,
      bearer
    );
    if (validation.success === 1) {
      this.setState({
        keyValid : true,
        key: validation.key,
        id: validation.sponsor.sponsor_id
      });
    }
  }

  componentDidMount() {
    
    this.validKey();
  }

  async setPasswordSubmit(password) {
    const bearer = await getBearer();

    let request = {};
    request['sponsor_id'] = this.state.id;
    request['password'] = password;
    request['key'] = this.state.key;

    const passwordSet = await callFetch(
      process.env.REACT_APP_API_URL+'api/sponsor/create_password',
      'POST',
      request,
      bearer
    );

    if (passwordSet.success === 1) {
      this.setState({
        passwordSet : true
      });
    }
  }
  

  render() {
    const classes = this.props.classes;
    const passwordRef = this.props.passwordRef;
    const navigate = this.props.navigate;
    return (
      <Page className={classes.root} title="Set Password">
        <Box
        display={this.state.passwordSet && this.state.keyValid?'flex':'none'}
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={1} mb={3} align="center">
              <Typography
                  align="center"
                  color="textSecondary"
                  variant="h1"
                >
                  Password Set
              </Typography>
            </Box>
            <Box mt={1} mb={3} align="center">
              <Button
                color="primary"
                size="large"
                type="button"
                variant="contained"
                onClick={() => { navigate('/', { replace: true }); }}
              >
                Go To Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        display={!this.state.keyValid?'flex':'none'}
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box mt={1} mb={3} align="center">
                      <Typography
                          align="center"
                          color="textSecondary"
                          variant="h1"
                        >
                          Validating...
                        </Typography>
                    </Box>
                    </Grid>
                    </Grid>
      </Box>
      <Box
        display={this.state.keyValid && !this.state.passwordSet?'flex':'none'}
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8, "Password must contain at least 8 characters")
                .required('Password is required'),
              confirmPassword: Yup.string("Enter your password")
                .required("Confirm your password")
                .oneOf([Yup.ref("password")], "Password does not match")
            })}
            onSubmit={() => {
              //navigate('/activation', { replace: true });
              //console.log(document.getElementsByName("password"));
              const form = passwordRef.current;
              let password = `${form['password'].value}`;
              this.setPasswordSubmit(password);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit} ref={passwordRef}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box mt={1} mb={3} align="center">
                      <Typography
                          align="center"
                          color="textSecondary"
                          variant="h1"
                        >
                          Set Password {this.state.text}
                        </Typography>
                    </Box>
                    
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      fullWidth
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      label="Confirm Password"
                      margin="normal"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      variant="outlined"
                    />
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Set Password
                      </Button>
                    </Box> 
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
    );
  }
}


export default () => {
  const navigate = useNavigate();
  const classes = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));
  const passwordRef = useRef(null);
  return (
      <SetPassword classes={classes} navigate={navigate} passwordRef={passwordRef}/>
  )
}