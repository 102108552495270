import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import LogoutView from 'src/views/auth/LogoutView';
import SetPassword from 'src/views/auth/SetPassword';
import Activation from 'src/views/auth/Activation';
import ForgotPassword from 'src/views/auth/ForgotPassword';
import Dashboard from 'src/views/reports/DashboardView/Dashboard';
import Reports from 'src/views/reports/ReportView/Reports';
import Invoices from 'src/views/reports/InvoiceView/Invoices';

const Routes = [
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: '/', element: <LoginView /> },
      { path: '/logout', element: <LogoutView /> },
      { path: '/validate_email/:validationKey', element: <SetPassword /> },
      { path: '/activation/', element: <Activation /> },
      { path: '/forgot-password/', element: <ForgotPassword /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/reports', element: <Reports /> },
      { path: '/invoices', element: <Invoices /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];




export default Routes;
