import React from 'react';
import {
  callFetch,
  getBearer,
  numberFormat,
  dateFormat
} from '../../../helper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Hidden } from '@material-ui/core';

class PerStudentInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: [],
      invoices: [],
      show: 0
    };

    this.getReports();
  }

  async getReports() {
    const bearer = await getBearer();
    let notEmpty = 0;

    let request = {};
    const report = await callFetch(
      process.env.REACT_APP_API_URL +
        'api/sponsor/' +
        this.props.student.sponsor_id +
        '/sponsorship/' +
        this.props.student.link_id +
        '/' +
        this.props.student.student_id +
        '/invoice/all',
      'GET',
      request,
      bearer
    );

    if (report.length > 0) {
      notEmpty = 1;
    }

    this.setState({
      invoices: report,
      show: notEmpty
    });
  }

  async downloadInvoice(id) {
    const bearer = await getBearer();

    let request = {};
    await callFetch(
      process.env.REACT_APP_API_URL + 'api/sponsor/download/invoice/' + id,
      'GET',
      request,
      bearer,
      true,
      'Invoice #' + id
    );
  }

  async downloadPayment(id) {
    const bearer = await getBearer();

    let request = {};
    await callFetch(
      process.env.REACT_APP_API_URL + 'api/sponsor/download/receipt/' + id,
      'GET',
      request,
      bearer,
      true,
      'Receipt #' + id
    );
  }

  render() {
    const invoices = this.state.invoices;
    const classes = this.props.pclasses;
    const student = this.props.student;

    return (
      <Box className="report-year" hidden={this.state.show === 0} mb={3}>
        <Box mb={1}>
          <h3>
            {student.firstname} {student.lastname}
          </h3>
        </Box>
        <Hidden smUp>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ background: 'rgba(68,12,18,0.25)' }}>
                <TableRow>
                  <TableCell style={{ color: '#000' }}>
                    Invoice Results
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, index) => (
                  <TableRow
                    key={invoice.invoice_id}
                    style={
                      index % 2 === 0
                        ? {}
                        : { background: 'rgba(247,148,30,0.05)' }
                    }
                  >
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>Invoice Number : </span>
                        <span>{invoice.invoice_id}</span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>Date : </span>
                        <span>{dateFormat(new Date(invoice.invoicedate))}</span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>Period : </span>
                        <span>
                          {dateFormat(new Date(invoice.monthfrom))} -{' '}
                          {dateFormat(new Date(invoice.monthuntil))}
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>Paid On : </span>
                        <span>
                          {invoice.paymentdate
                            ? dateFormat(new Date(invoice.paymentdate))
                            : 'Unpaid'}
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>Amount : </span>
                        <span>{numberFormat(invoice.amount)}</span>
                      </Box>
                      <Box display="flex" flexDirection="row">
                        <Button
                          color="secondary"
                          fullWidth
                          size="small"
                          type="button"
                          variant="contained"
                          style={{ marginRight: '5px' }}
                          onClick={() => {
                            this.downloadInvoice(invoice.invoice_id);
                          }}
                        >
                          Invoice
                        </Button>
                        <Button
                          display="inline"
                          disabled={invoice.paymentdate === null}
                          color="primary"
                          fullWidth
                          size="small"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            this.downloadPayment(invoice.payment_id);
                          }}
                        >
                          Receipt
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
        <Hidden xsDown>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ background: 'rgba(68,12,18,0.25)' }}>
                <TableRow>
                  <TableCell style={{ color: 'rgba(0,0,0,1)' }}>
                    Invoice Number
                  </TableCell>
                  <TableCell style={{ color: 'rgba(0,0,0,1)' }}>Date</TableCell>
                  <TableCell style={{ color: 'rgba(0,0,0,1)' }}>
                    Period
                  </TableCell>
                  <TableCell style={{ color: 'rgba(0,0,0,1)' }}>
                    Paid On
                  </TableCell>
                  <TableCell style={{ color: 'rgba(0,0,0,1)' }} align="right">
                    Amount
                  </TableCell>
                  <TableCell style={{ color: 'rgba(0,0,0,1)' }} align="center">
                    Download
                  </TableCell>
                  {/* <TableCell align="center">Receipt</TableCell> */}
                  {/* <TableCell align="center">
                <Button
                  color="primary"
                  fullWidth
                  size="small"
                  type="button"
                  variant="contained"
                >
                  Download
                </Button>
              </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, index) => (
                  <TableRow
                    key={invoice.invoice_id}
                    style={
                      index % 2 === 0
                        ? {}
                        : { background: 'rgba(247,148,30,0.05)' }
                    }
                  >
                    <TableCell>{invoice.invoice_id}</TableCell>
                    <TableCell>
                      {dateFormat(new Date(invoice.invoicedate))}
                    </TableCell>
                    <TableCell>
                      {dateFormat(new Date(invoice.monthfrom))} -{' '}
                      {dateFormat(new Date(invoice.monthuntil))}
                    </TableCell>
                    <TableCell>
                      {invoice.paymentdate
                        ? dateFormat(new Date(invoice.paymentdate))
                        : 'Unpaid'}
                    </TableCell>
                    <TableCell align="right">
                      {numberFormat(invoice.amount)}
                    </TableCell>
                    <TableCell width={100} align="center">
                      <Box display="flex" flexDirection="row">
                        <Button
                          color="secondary"
                          fullWidth
                          size="small"
                          type="button"
                          variant="contained"
                          style={{ marginRight: '5px' }}
                          onClick={() => {
                            this.downloadInvoice(invoice.invoice_id);
                          }}
                        >
                          Invoice
                        </Button>
                        <Button
                          display="inline"
                          disabled={invoice.paymentdate === null}
                          color="primary"
                          fullWidth
                          size="small"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            this.downloadPayment(invoice.payment_id);
                          }}
                        >
                          Receipt
                        </Button>
                      </Box>
                    </TableCell>
                    {/* <TableCell align="center">
                    <Button
                      disabled={invoice.paymentdate === null}
                      color="primary"
                      fullWidth
                      size="small"
                      type="button"
                      variant="contained"
                      onClick={() => {
                        this.downloadPayment(invoice.payment_id);
                      }}
                    >
                      Receipt
                    </Button>
                  </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
      </Box>
    );
  }
}

export default PerStudentInvoice;
