import Cookies from 'universal-cookie';

export async function callFetch(
  url,
  method,
  request = false,
  auth = false,
  isPdf = false,
  filename = 'file'
) {
  var formdata = new FormData();

  if (request !== false) {
    for (const [key, value] of Object.entries(request)) {
      formdata.append(key, value);
    }
  }

  var requestOptions = {};

  if (method === 'POST') {
    requestOptions = {
      method: method,
      body: formdata
    };
  }

  if (method === 'GET' && isPdf) {
    requestOptions = {
      responseType: 'blob'
    };
  }

  var myHeaders = new Headers();

  if (auth !== false) {
    myHeaders.append('Authorization', 'Bearer ' + auth);
  }
  requestOptions['headers'] = myHeaders;

  const response = await fetch(url, requestOptions);

  if (!isPdf) {
    const json = await response.json();
    return json;
  } else {
    const blob = await response.blob();

    const newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = filename + '.pdf';
    link.click();
    setTimeout(function() {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}

export async function getBearer() {
  const cookies = new Cookies();
  let currentBearer = cookies.get('bearer');
  if (currentBearer) {
    return currentBearer;
  } else {
    let request = {};
    request['grant_type'] = 'client_credentials';
    request['client_id'] = process.env.REACT_APP_CLIENT_ID;
    request['client_secret'] = process.env.REACT_APP_CLIENT_PASSWORD;
    const bearer = await callFetch(
      process.env.REACT_APP_API_URL + 'oauth/token',
      'POST',
      request
    );

    var expiryDate = new Date();
    expiryDate.setHours(expiryDate.getHours() + 10);

    cookies.set('bearer', bearer.access_token, {
      path: '/',
      expires: expiryDate
    });
    return bearer.access_token;
  }
}

export const numberFormat = value => {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD'
  }).format(value);
};

export const dateFormat = value => {
  return new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  }).format(value);
};
