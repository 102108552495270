import React, { useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Cookies from 'universal-cookie';
import { callFetch, getBearer } from '../../helper';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById('btnSpin').style.display = 'none';
  }

  async LoginSubmit(email, password) {
    const bearer = await getBearer();
    //const navigate = this.props.navigate;
    const cookies = this.props.cookies;
    let request = {};
    request['email'] = email;
    request['password'] = password;

    const login = await callFetch(
      process.env.REACT_APP_API_URL + 'api/user/login',
      'POST',
      request,
      bearer
    );
    if (login.success === 1) {
      cookies.set('loginkey', login.loginkey, { path: '/' });
      window.location.replace('/dashboard', { replace: true });
      return true;
    } else {
      document.getElementById('form-login').style.display = 'none';
      document.getElementById('failed-login').style.display = 'block';
      return false;
    }
  }

  render() {
    const classes = this.props.classes;
    const loginRef = this.props.loginRef;
    const navigate = this.props.navigate;
    return (
      <Page className={classes.root} title="Login">
        <Box
          display="flex"
          marginTop="24px"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required'),
                password: Yup.string()
                  .max(255)
                  .required('Password is required')
              })}
              onSubmit={(values, actions) => {
                //navigate('/app/dashboard', { replace: true });
                const form = loginRef.current;
                let email = `${form['email'].value}`;
                let password = `${form['password'].value}`;
                actions.setSubmitting(true);
                document.getElementById('btnSpin').style.display = 'block';
                this.LoginSubmit(email, password);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <div>
                  <div hidden="hidden" id="failed-login">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box mt={1} mb={3} align="center">
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="h1"
                          >
                            Login Failed
                          </Typography>
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="body1"
                          >
                            Your detail does not match or your account was not
                            activated. Please try again.
                          </Typography>
                          <Box my={2}>
                            <Button
                              color="primary"
                              fullWidth
                              size="large"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                window.location.reload(false);
                              }}
                            >
                              Try Again
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                  <form onSubmit={handleSubmit} ref={loginRef} id="form-login">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box mt={1} mb={3} align="center">
                          <img alt="Logo" src="/static/logo-wide.png" />
                        </Box>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          label="Email Address"
                          margin="normal"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          placeholder="email@domain.com"
                          variant="outlined"
                        />
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label="Password"
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password}
                          variant="outlined"
                        />
                        <Typography
                          color="textSecondary"
                          variant="body1"
                          align="right"
                        >
                          <Link
                            component={RouterLink}
                            to="/forgot-password"
                            variant="h6"
                          >
                            Forgot Password
                          </Link>
                        </Typography>
                        <Box my={2}>
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            <span id="btnText" hidden={isSubmitting}>
                              Sign in
                            </span>
                            <CircularProgress id="btnSpin" color="success" />
                          </Button>
                        </Box>

                        <Box mt={1} mb={3}>
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="body1"
                          >
                            OR
                          </Typography>
                        </Box>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              fullWidth
                              onClick={() => {
                                navigate('/activation', { replace: true });
                              }}
                              size="large"
                              variant="contained"
                            >
                              Activate
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="secondary"
                              fullWidth
                              onClick={() => {
                                window.location.replace(
                                  'https://www.australiaoikoscare.org/become-sponsor/'
                                );
                              }}
                              size="large"
                              variant="contained"
                            >
                              Become a sponsor
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              )}
            </Formik>
          </Container>
        </Box>
      </Page>
    );
  }
}

export default () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const loginRef = useRef(null);
  const classes = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));
  return (
    <LoginView
      classes={classes}
      navigate={navigate}
      cookies={cookies}
      loginRef={loginRef}
    />
  );
};
