import React from 'react';

const PhotoProfile = (props) => {
  return (
    <img
      alt="User Profile"
      width="130px"
      height="130px"
      src="/static/images/user.svg"
      {...props}
    />
  );
};

export default PhotoProfile;
