import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, makeStyles, Box, Hidden } from '@material-ui/core';
import Page from 'src/components/Page';
import { callFetch, getBearer } from '../../../helper';
import Cookies from 'universal-cookie';
import LeftMenu from '../LeftMenu';
import YearlyReports from './YearlyReports';
import { CircularProgress } from '@material-ui/core';

class Reports extends React.Component {
  constructor(props) {
    super(props);
    const options = [];
    const years = [];
    const cYear = moment('year');

    var today = moment().set({
      date: 1,
      month: 6,
      year: cYear,
      hour: 0,
      minute: 0,
      second: 0
    });
    var endFY = moment().set({
      date: 30,
      month: 5,
      hour: 0,
      minute: 0,
      second: 0
    });
    var sinceYear = moment()
      .subtract(6, 'year')
      .year();

    var endFYYear = endFY.year();
    if (today > endFY) {
      endFYYear = endFY.year() + 1;
    }
    // 7 years

    // options.push(<option value='All'>All</option>)
    for (var i = sinceYear; i <= endFYYear; i++) {
      //   options.push(<option value={i-1 + '/' + i}>{i-1 + '/' +i}</option>);
      years.unshift(i - 1 + '-' + i);
    }

    this.state = {
      keyValid: false,
      passwordSet: false,
      id: 0,
      key: '',
      sponsor: [],
      contractCount: 0,
      contracts: [],
      invoices: [],
      options: options,
      years: years,
      sid: '',
      isLoading: 1
    };
  }

  async isLogin() {
    const navigate = this.props.navigate;
    const bearer = await getBearer();
    const cookies = new Cookies();
    let loginkey = cookies.get('loginkey');

    let request = {};
    request['loginkey'] = loginkey;

    const validation = await callFetch(
      process.env.REACT_APP_API_URL + 'api/user/islogin',
      'POST',
      request,
      bearer
    );
    if (validation.success === 0) {
      navigate('/', { replace: true });
    } else {
      this.setState({
        sponsor: validation.sponsor,
        contractCount: validation.contract_count,
        contracts: validation.contracts,
        invoices: validation.nextInvoices,
        currentYear: validation.currentYear,
        sid: validation.sid,
        isLoading: 0
      });
    }
  }

  loadReport() {
    if (this.state.isLoading === 0) {
      return (
        <Grid item xs={12}>
          {Object.keys(this.state.years).map(key => (
            <YearlyReports
              pclasses={this.props.classes}
              sid={this.state.sid}
              year={this.state.years[key]}
            />
          ))}
        </Grid>
      );
    }
  }

  componentDidMount() {
    this.isLogin();
  }

  render() {
    const classes = this.props.classes;
    //var contracts = this.state.contracts;
    //var invoices = this.state.invoices;

    //if (this.state.isLoad === 1)
    return this.state.isLoading ? (
      <div
        style={{
          textAlign: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <CircularProgress color="success" />
      </div>
    ) : (
      <Page className={classes.root} title="Dashboard">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Hidden smDown>
              <Grid item md={3}>
                <LeftMenu sponsor={this.state.sponsor} />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={9}>
              {/* <Main /> */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    mt={3}
                    lineHeight="2"
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="2px solid #ff853f"
                    textAlign="left"
                  >
                    <h3>Sponsorship Reports</h3>
                    {/* <Select native>
                      {this.state.options}
                    </Select> */}
                  </Box>
                </Grid>

                {this.loadReport()}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}

export default () => {
  const navigate = useNavigate();
  const classes = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));
  return <Reports classes={classes} navigate={navigate} />;
};
