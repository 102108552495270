import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Select,
  Hidden
} from '@material-ui/core';
import Page from 'src/components/Page';
import { callFetch, getBearer } from '../../../helper';
import Cookies from 'universal-cookie';
import LeftMenu from '../LeftMenu';
import PerStudentInvoice from './PerStudentInvoice';
import { CircularProgress } from '@material-ui/core';

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    const options = [];
    const years = [];
    const cYear = moment('year');

    var today = moment().set({
      date: 1,
      month: 6,
      year: cYear,
      hour: 0,
      minute: 0,
      second: 0
    });
    var endFY = moment().set({
      date: 30,
      month: 5,
      hour: 0,
      minute: 0,
      second: 0
    });
    var sinceYear = moment()
      .subtract(6, 'year')
      .year();

    var endFYYear = endFY.year();
    if (today > endFY) {
      endFYYear = endFY.year() + 1;
    }
    for (var i = sinceYear; i <= endFYYear; i++) {
      years.unshift(i - 1 + '-' + i);
    }

    this.state = {
      keyValid: false,
      passwordSet: false,
      id: 0,
      key: '',
      sponsor: [],
      contractCount: 0,
      contracts: [],
      invoices: [],
      options: options,
      years: years,
      sid: '',
      isLoading: 1,
      selectStudent: 'All'
    };

    this.studentChange = this.studentChange.bind(this);
  }

  async isLogin() {
    const navigate = this.props.navigate;
    const bearer = await getBearer();
    const cookies = new Cookies();
    let loginkey = cookies.get('loginkey');

    let request = {};
    request['loginkey'] = loginkey;

    const validation = await callFetch(
      process.env.REACT_APP_API_URL + 'api/user/islogin',
      'POST',
      request,
      bearer
    );
    if (validation.success === 0) {
      navigate('/', { replace: true });
    } else {
      this.setState({
        sponsor: validation.sponsor,
        contractCount: validation.contract_count,
        contracts: validation.contracts,
        invoices: validation.nextInvoices,
        currentYear: validation.currentYear,
        sid: validation.sid,
        isLoading: 0
      });
    }
  }

  componentDidMount() {
    this.isLogin();
  }

  studentChange(event, child) {
    this.setState({
      selectStudent: event.target.value
    });
  }

  render() {
    const classes = this.props.classes;
    //var contracts = this.state.contracts;
    var invoices = this.state.invoices;

    //if (this.state.isLoad === 1)
    return this.state.isLoading ? (
      <div
        style={{
          textAlign: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <CircularProgress color="success" />
      </div>
    ) : (
      <Page className={classes.root} title="Dashboard">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Hidden smDown>
              <Grid item md={3}>
                <LeftMenu sponsor={this.state.sponsor} />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={9}>
              {/* <Main /> */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    mt={3}
                    lineHeight="2"
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="2px solid #ff853f"
                    textAlign="left"
                  >
                    <h3>Sponsorship Invoices</h3>
                    <Select native onChange={this.studentChange}>
                      <option key={'All'} value={'All'}>
                        All
                      </option>
                      {invoices.map(invoice => (
                        <option
                          key={invoice.student_id}
                          value={invoice.student_id}
                        >
                          {invoice.firstname} {invoice.lastname}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    mt={3}
                    lineHeight="2"
                    display="flex"
                    justifyContent="left"
                    textAlign="left"
                  >
                    {/* {invoices.map((invoice) => (
                      <Button
                        color="primary"
                        size="small"
                        type="button"
                        variant="contained"
                        key={invoice.student_id}
                        mr={3}
                      >
                        {invoice.firstname} {invoice.lastname}
                      </Button>
                    ))} */}
                  </Box>
                </Grid>
                {invoices.map(invoice => (
                  <Grid
                    item
                    xs={12}
                    key={invoice.student_id}
                    hidden={
                      this.state.selectStudent !== 'All' &&
                      this.state.selectStudent !== invoice.student_id
                    }
                  >
                    <PerStudentInvoice
                      pclasses={this.props.classes}
                      student={invoice}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}

export default () => {
  const navigate = useNavigate();
  const classes = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));
  return <Invoices classes={classes} navigate={navigate} />;
};
