import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Container,
  AppBar,
  Toolbar,
  Hidden,
  makeStyles,
  Typography,
  Box
} from '@material-ui/core';

import Logo from 'src/components/Logo';
import Sidebar from './Sidebar';
import theme from 'src/theme';
import { callFetch, getBearer } from '../../helper';
import Cookies from 'universal-cookie';

class TopBar extends React.Component {
  async isLogin() {
    const navigate = this.props.navigate;
    const bearer = await getBearer();
    const cookies = new Cookies();
    let loginkey = cookies.get('loginkey');
    let request = {};
    request['loginkey'] = loginkey;
    const validation = await callFetch(
      process.env.REACT_APP_API_URL + 'api/user/islogin',
      'POST',
      request,
      bearer
    );
    if (validation.success === 1) {
      navigate('/dashboard', { replace: true });
    }
  }

  componentDidMount() {
    this.isLogin();
  }

  render() {
    const classes = this.props.classes;

    const mystyle = {
      color: 'white',
      fontSize: 24,
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        fontSize: 35
      }
    };

    return (
      <AppBar
        className={clsx(classes.root, PropTypes.string)}
        elevation={0}
        color="secondary"
      >
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <a
              target="_self"
              href="https://www.australiaoikoscare.org"
              title="Go to Australia Oikos Care website"
            >
              <Logo />
            </a>
            <Box mx={4} style={{ flexGrow: '2' }}>
              <Typography variant="h1">
                <RouterLink
                  className={classes.appTitle}
                  to="/dashboard"
                  style={mystyle}
                  title="Go to AOC Sponsorship Portal page"
                >
                  Sponsorship Portal
                </RouterLink>
              </Typography>
            </Box>
            <Hidden mdUp>
              <Sidebar />
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default () => {
  const navigate = useNavigate();
  const useStyles = makeStyles({
    root: {},
    toolbar: {
      height: 64
    },
    appTitle: {
      color: '#ffffff !important',
      fontSize: 24,
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        fontSize: 35
      }
    }
  });

  return <TopBar classes={useStyles} navigate={navigate} />;
};
