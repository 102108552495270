import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Cookies from 'universal-cookie';
import { callFetch, getBearer } from '../../helper';
import CircularProgress from '@material-ui/core/CircularProgress';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById('btnSpin').style.display = 'none';
  }

  async ForgotSubmit(email, birthday, lastName) {
    const bearer = await getBearer();

    let request = {};
    request['email'] = email;
    request['birthday'] = birthday;
    request['lastname'] = lastName;
    request['for'] = 'forgot_pass';

    try {
      const active = await callFetch(
        process.env.REACT_APP_API_URL + 'api/sponsor/activate',
        'POST',
        request,
        bearer
      );
      if (active.success === 1) {
        document.getElementById('form-activation').style.display = 'none';
        if (active.success === 1) {
          document.getElementById('activation-name').innerHTML = active.name;
          document.getElementById('success-activation').style.display = 'block';
        } else {
          document.getElementById('failed-activation').style.display = 'block';
        }
      } else {
        document.getElementById('failed-activation').style.display = 'block';
      }
    } catch (err) {
      document.getElementById('form-activation').style.display = 'none';
      document.getElementById('failed-activation').style.display = 'block';
    }
  }

  render() {
    const classes = this.props.classes;
    const activationRef = this.props.activationRef;
    return (
      <Page className={classes.root} title="Login">
        <Box flexDirection="column" height="100%" justifyContent="center">
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                email: '',
                birth: '',
                lastName: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Email is required'),
                birth: Yup.date().required('Birthday is required'),
                lastName: Yup.string().required('Lastname is required')
              })}
              onSubmit={(values, actions) => {
                //navigate('/app/dashboard', { replace: true });
                const form = activationRef.current;
                let email = `${form['email'].value}`;
                let birthday = `${form['birth'].value}`;
                let lastName = `${form['lastName'].value}`;
                actions.setSubmitting(true);
                document.getElementById('btnSpin').style.display = 'block';
                this.ForgotSubmit(email, birthday, lastName);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <div>
                  <div hidden="hidden" id="failed-activation">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box mt={1} mb={3} align="center">
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="h1"
                          >
                            Forgot Password Failed
                          </Typography>
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="body1"
                          >
                            Your detail is not match or your account is not
                            activate. Please try again.
                          </Typography>
                          <Box my={2}>
                            <Button
                              color="primary"
                              fullWidth
                              size="large"
                              type="button"
                              variant="contained"
                              onClick={() => {
                                window.location.reload(false);
                              }}
                            >
                              Try Again
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                  <div hidden="hidden" id="success-activation">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box mt={1} mb={3} align="center">
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="h1"
                          >
                            Forgot Password Success
                          </Typography>
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="body1"
                          >
                            Hi <span id="activation-name">Name</span>, Please
                            check your email, to re-create your password.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                  <form
                    id="form-activation"
                    onSubmit={handleSubmit}
                    ref={activationRef}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box mt={1} mb={3} align="center">
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="h1"
                          >
                            Forgot Password
                          </Typography>
                        </Box>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          label="Email Address"
                          margin="normal"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                          placeholder="email@domain.com"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        <TextField
                          error={Boolean(touched.birth && errors.birth)}
                          fullWidth
                          helperText={touched.birth && errors.birth}
                          label="Birthday"
                          margin="normal"
                          name="birth"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="date"
                          value={values.birth}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        <TextField
                          error={Boolean(touched.lastName && errors.lastName)}
                          fullWidth
                          helperText={touched.lastName && errors.lastName}
                          label="Last Name"
                          margin="normal"
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.lastName}
                          variant="outlined"
                          placeholder="Last name"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        <Box my={2}>
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            <span id="btnText" hidden={isSubmitting}>
                              Submit
                            </span>
                            <CircularProgress id="btnSpin" color="success" />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              )}
            </Formik>
          </Container>
        </Box>
      </Page>
    );
  }
}

export default () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const activationRef = useRef(null);
  const classes = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));
  return (
    <ForgotPassword
      classes={classes}
      navigate={navigate}
      cookies={cookies}
      activationRef={activationRef}
    />
  );
};
