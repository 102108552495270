import React from 'react';
import {
  callFetch,
  getBearer,
  numberFormat,
  dateFormat
} from '../../../helper';
import { Grid, Box } from '@material-ui/core';
import { Pie, Bar } from 'react-chartjs-2';

class TopDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sid: this.props.sid,
      year: this.props.year,
      dashboard: [],
      pieChart: {},
      lineChart: {}
    };

    this.getDashboard();
  }

  async getDashboard() {
    const bearer = await getBearer();

    const color = [
      '#FFB68D',
      '#7DA5D4',
      '#FFD38D',
      '#75D4BA',
      '#B84605',
      '#0D3E78'
    ];

    let request = {};
    const topDashboard = await callFetch(
      process.env.REACT_APP_API_URL +
        'api/sponsor/' +
        this.props.sid +
        '/dashboard/' +
        this.props.year.toLowerCase(),
      'GET',
      request,
      bearer
    );

    // pie chart - start
    let labels = [];
    let dataChart = [];
    let pieColor = [];

    for (let i = 0; i < topDashboard.sponsorDemography.length; i++) {
      labels.push(topDashboard.sponsorDemography[i].state);
      dataChart.push(topDashboard.sponsorDemography[i].count_sponsor);
      pieColor.push(color[i]);
    }

    let pieChart = {
      labels: labels,
      datasets: [
        {
          label: 'Sponsor Demography',
          data: dataChart,
          backgroundColor: pieColor,
          borderColor: pieColor,
          borderWidth: 1
        }
      ]
    };
    // pie chart - end

    // line chart - start
    let lineLabels = [];
    let lineData = [];
    let yearlyData = [];
    let k = 0;
    for (let i = topDashboard.donationHistory.length - 1; i >= 0; i--) {
      if (!lineLabels.includes(topDashboard.donationHistory[i][1])) {
        lineLabels.push(topDashboard.donationHistory[i][1]);
      }
      let quater = topDashboard.donationHistory[i][0];
      let year = topDashboard.donationHistory[i][1];

      yearlyData[year + '-' + quater] = topDashboard.donationHistory[i][2];

      // let quater = topDashboard.donationHistory[i][0]-1;
      // let total = topDashboard.donationHistory[i][2];
      // if (lineData[quater] === undefined || lineData[quater] === null) {
      //   lineData[quater] = [];
      //   j = 0;
      // } else {
      //   j = lineData[quater].length;
      // }
      // lineData[quater][j] = total;
    }

    let lineDataChart = [];

    for (let i = 0; i < lineLabels.length; i++) {
      for (let j = 1; j <= 4; j++) {
        let l = j - 1;
        if (lineData[l] === undefined || lineData[l] === null) {
          lineData[l] = [];
          k = 0;
        } else {
          k = lineData[l].length;
        }

        if (yearlyData[lineLabels[i] + '-' + j] === undefined) {
          lineData[l][k] = 0;
        } else {
          lineData[l][k] = yearlyData[lineLabels[i] + '-' + j];
        }
      }
    }

    for (let i = 0; i < lineData.length; i++) {
      lineDataChart[i] = {
        label: 'Qtr ' + (i + 1),
        data: lineData[i],
        backgroundColor: color[i]
      };
    }

    let lineChart = {
      labels: lineLabels,
      datasets: lineDataChart
    };

    // console.log(lineChart);

    // line chart - end

    this.setState({
      dashboard: topDashboard,
      pieChart: pieChart,
      lineChart: lineChart
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.getDashboard();
    }
  }

  render() {
    return (
      <>
        {this.state.dashboard.isTopTenGiver && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Box
                p={1}
                border={1}
                borderRadius={'5px'}
                bgcolor={'#ff853f'}
                borderColor={'#ff853f'}
                color="#fff"
              >
                <h4>Congratulation you are a our Top Ten giver.</h4>
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box
              px={2}
              pt={2}
              lineHeight="2"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              //  bgcolor="#17a2b8"
              color="#fff"
              textAlign="left"
            >
              <h4
                style={{
                  backgroundColor: '#ff853f',
                  padding: '0 16px',
                  marginRight: '-16px',
                  marginLeft: '-16px',
                  borderRadius: '5px 5px 0px 0px'
                }}
              >
                Total Donation YTD
              </h4>
              <div
                style={{
                  border: '1px solid #ff853f',
                  margin: '0 -16px',
                  padding: '16px',
                  color: '#000',
                  textAlign: 'right'
                }}
              >
                <b>{numberFormat(this.state.dashboard.totalDonationYTD)}</b>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              px={2}
              pt={2}
              lineHeight="2"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              //  bgcolor="#17a2b8"
              color="#fff"
              textAlign="left"
            >
              <h4
                style={{
                  backgroundColor: '#ff853f',
                  padding: '0 16px',
                  marginRight: '-16px',
                  marginLeft: '-16px',
                  borderRadius: '5px 5px 0px 0px'
                }}
              >
                Your Donation YTD
              </h4>
              <div
                style={{
                  border: '1px solid #ff853f',
                  margin: '0 -16px',
                  padding: '16px',
                  color: '#000',
                  textAlign: 'right'
                }}
              >
                <b>
                  {numberFormat(this.state.dashboard.sponsorDonationYTD)} /{' '}
                  {this.state.dashboard.sponsorDonationContribution}%
                </b>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              px={2}
              pt={2}
              lineHeight="2"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              //  bgcolor="#17a2b8"
              color="#fff"
              textAlign="left"
            >
              <h4
                style={{
                  backgroundColor: '#ff853f',
                  padding: '0 16px',
                  marginRight: '-16px',
                  marginLeft: '-16px',
                  borderRadius: '5px 5px 0px 0px'
                }}
              >
                Next Invoice
              </h4>

              <div
                style={{
                  border: '1px solid #ff853f',
                  margin: '0 -16px',
                  padding: '16px',
                  color: '#000',
                  textAlign: 'right'
                }}
              >
                <b>
                  {this.state.dashboard.nextInvoiceDate?.length > 0
                    ? this.state.dashboard.nextInvoiceDate.map(nextInvoice => (
                        <Box>
                          {nextInvoice.studentFirstname}
                          {' : '}
                          {dateFormat(new Date(nextInvoice.nextInvoiceDate))}
                        </Box>
                      ))
                    : 'Not applicable'}
                </b>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box
              px={2}
              pt={2}
              lineHeight="2"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              //   bgcolor="#17a2b8"
              color="#fff"
              textAlign="left"
            >
              <h4
                style={{
                  backgroundColor: '#ff853f',
                  padding: '0 16px',
                  marginRight: '-16px',
                  marginLeft: '-16px',
                  borderRadius: '5px 5px 0px 0px'
                }}
              >
                Sponsor Demography
              </h4>
              <div
                style={{
                  border: '1px solid #ff853f',
                  margin: '0 -16px',
                  padding: '10px',
                  color: '#000'
                }}
              >
                <Pie
                  data={this.state.pieChart}
                  options={{
                    responsive: true,
                    legend: {
                      labels: { fontColor: 'black' }
                    }
                  }}
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              px={2}
              pt={2}
              lineHeight="2"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              //  bgcolor="#17a2b8"
              color="#fff"
              textAlign="left"
            >
              <h4
                style={{
                  backgroundColor: '#ff853f',
                  padding: '0 16px',
                  marginRight: '-16px',
                  marginLeft: '-16px',
                  borderRadius: '5px 5px 0px 0px'
                }}
              >
                Total Donation History
              </h4>
              <div
                style={{
                  border: '1px solid #ff853f',
                  margin: '0 -16px',
                  padding: '10px',
                  color: '#000'
                }}
              >
                <Bar
                  data={this.state.lineChart}
                  options={{
                    responsive: true,
                    legend: {
                      labels: {
                        fontColor: 'black',
                        boxWidth: 20
                      }
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            fontColor: 'black',
                            fontSize: 12,
                            stepSize: 1000
                          }
                        }
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: 'black',
                            fontSize: 12,
                            stepSize: 500
                          }
                        }
                      ]
                    }
                  }}
                  height={150}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default TopDashboard;
