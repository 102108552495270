import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Typography,
  Box,
  Grid,
  Container
} from '@material-ui/core';
import theme from 'src/theme';

const useStyles = makeStyles({
  root: {},
  footerText: {
    fontSize: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  },
  footerWrapper: {
    background: '#333',
    position: 'absolute',
    bottom: 0,
    color: '#ffffff !important'
  },
  footerLeftWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      height: 20
    }
  },
  footerLi: {
    float: 'right',
    listStyleType: 'none',
    marginLeft: 12,
    [theme.breakpoints.down('xs')]: {
      float: 'right'
    }
  },
  footerLiWrapper: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      height: 40
    }
  },
  footer: {
    marginTop: 100
  },
  footerLink: {
    color: '#fff !important',
    borderBottom: 'dotted 1px #fff'
  }
});

const Footer = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="100%" className={classes.footerWrapper}>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              lg={3}
              xl={3}
              md={4}
              sm={5}
              className={classes.footerLeftWrapper}
            >
              <Box mt={2} mb={2}>
                <Typography className={classes.footerText} align="middle">
                  Copyright &copy;2022 AOC Dev Team
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={8}
              lg={9}
              className={classes.footerLiWrapper}
            >
              <Box mt={2} mb={2} align="left">
                <Typography className={classes.footerText}>
                  <ul>
                    <li className={classes.footerLi}>
                      <a
                        target="_blank"
                        className={classes.footerLink}
                        rel="noopener noreferrer"
                        href="https://www.australiaoikoscare.org/about-us/privacy-policy/"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className={classes.footerLi}> | </li>
                    <li className={classes.footerLi}>
                      <a
                        target="_blank"
                        className={classes.footerLink}
                        rel="noopener noreferrer"
                        href="https://www.australiaoikoscare.org/about-us/term-and-conditions/"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
