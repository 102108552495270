import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, makeStyles, Box, Link } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PhotoProfile from 'src/components/PhotoProfile';

//import Page from 'src/components/Page';
//import {callFetch, getBearer} from '../../../helper';
//import Cookies from 'universal-cookie';

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const sponsor = this.props.sponsor;
    //=const classes = this.props.classes;
    const navigate = this.props.navigate;
    return (
      <Container maxWidth={false}>
        <Grid container spacing={0}>
          {/* <Grid item lg={12}><Box textAlign="center" mt={1}>logo</Box></Grid> */}
          <Grid item xs={12}>
            <Box mt={3} style={{ textAlign: 'center' }}>
              <PhotoProfile />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center" mt={1}>
              {sponsor.firstname}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              textAlign="center"
              overflow="hidden"
              textOverflow="ellipsis"
              mt={1}
            >
              {sponsor.email}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center" mt={1} mb={2}>
              {/* <Button variant="contained" color="primary">
                Edit Profile
              </Button> */}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box textAlign="Left" my={1} px={1}>
              <Link
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={() => {
                  navigate('/dashboard', { replace: true });
                }}
                title="Go to dashboard page"
              >
                <DashboardIcon
                  style={{ marginRight: '0.5rem' }}
                  fontSize="small"
                />
                Dashboard
              </Link>
            </Box>
            <Box textAlign="Left" my={1} px={1}>
              <Link
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={() => {
                  navigate('/invoices', { replace: true });
                }}
                title="Go to invoice page"
              >
                <ReceiptIcon
                  style={{ marginRight: '0.5rem' }}
                  fontSize="small"
                />
                Invoices
              </Link>
            </Box>
            {/* <Box textAlign="Left" my={1} px={1}><Link href="#" title="Go to payment page">Payments</Link></Box> */}
            <Box textAlign="Left" my={1} px={1}>
              <Link
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={() => {
                  navigate('/reports', { replace: true });
                }}
                title="Go to report page"
              >
                <AssessmentIcon
                  style={{ marginRight: '0.5rem' }}
                  fontSize="small"
                />
                Reports
              </Link>
            </Box>
            <Box textAlign="Left" my={1} px={1}>
              <Link
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={() => {
                  navigate('/logout', { replace: true });
                }}
                title="Go to login page"
              >
                <ExitToAppIcon
                  style={{ marginRight: '0.5rem' }}
                  fontSize="small"
                />
                Logout
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default props => {
  const navigate = useNavigate();
  const classes = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    leftFotoWrapper: {
      textAlign: 'center'
    },
    leftMenuLink: {
      cursor: 'pointer'
    }
  }));
  const sponsor = props.sponsor;
  return <LeftMenu classes={classes} navigate={navigate} sponsor={sponsor} />;
};
