import React from 'react';
import { Grid } from '@material-ui/core';
import { callFetch, getBearer } from '../../../helper';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress  from '@material-ui/core/LinearProgress';

const styles = theme => ({
  newsBox: {},
  newsContent: {
    background: '#f8f8f8',
    padding: '20px',
    position: 'relative'
  },
  newsContentHeader: {
    marginBottom: '0.5rem'
  },
  image: {
    display: 'block',
    width: '100%',
    height: '185px',
    objectFit: 'cover'
  },
  newsPublishedDate: {
    position: 'absolute',
    right: '20px',
    bottom: '92.5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f1f1f1'
  },
  publishedDate: {
    padding: '5px 15px',
    fontWeight: '700',
    fontSize: '16px'
  },
  publishedMonth: {
    padding: '5px 15px',
    backgroundColor: '#ff853f',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '12px'
  }
});

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allNews: []
    };

    this.getAllNews();
  }

  async getAllNews() {
    const bearer = await getBearer();

    let request = {};
    const allNews = await callFetch(
      'https://australiaoikoscare.org/wp-json/wp/v2/posts?per_page=3',
      'GET',
      request,
      bearer
    );

    this.setState({
      allNews: allNews
    });
  }

  render() {
    var allNews = this.state.allNews;
    const { classes } = this.props;
    var options = { month: 'short' };
    if (allNews.length > 0) {
      return (
        <Grid container spacing={3}>
          {allNews.map(news => (
            <Grid item sm={4} key={news.id}>
              <a rel="noopener noreferrer" target="_blank" href={news.link}>
                <div className={classes.newsBox}>
                  <img
                    width="100%"
                    className={classes.image}
                    src={news.yoast_head_json.og_image[0].url}
                    alt={news.title.rendered}
                  />
                  <div className={classes.newsContent}>
                    <h4 className={classes.newsContentHeader}>
                      {news.title.rendered}
                    </h4>
                    <p
                      dangerouslySetInnerHTML={{ __html: news.excerpt.rendered }}
                    ></p>
                    <div className={classes.newsPublishedDate}>
                      <div className={classes.publishedDate}>
                        {new Date(news.modified).getDate()}
                      </div>
                      <div className={classes.publishedMonth}>
                        {new Intl.DateTimeFormat('en-AU', options).format(
                          new Date(news.modified)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return <LinearProgress style={{marginTop:-20}}/>;
    }
  }
}

export default withStyles(styles)(News);
