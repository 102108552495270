import React from 'react';
import { callFetch, getBearer, numberFormat } from '../../../helper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Grid, Hidden } from '@material-ui/core';

class YearlyReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sid: '',
      year: '',
      reports: [],
      show: 0
    };
  }

  async getReports() {
    const bearer = await getBearer();
    let notEmpty = 0;

    let request = {};
    const report = await callFetch(
      process.env.REACT_APP_API_URL +
        'api/sponsor/' +
        this.props.sid +
        '/sponsorship/statement/' +
        this.props.year,
      'GET',
      request,
      bearer
    );

    if (report.length > 0) {
      notEmpty = 1;
    }

    this.setState({
      reports: report,
      show: notEmpty
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.getReports();
    }
  }

  componentDidMount() {
    this.getReports();
  }

  async downloadReport(year) {
    const bearer = await getBearer();

    let request = {};
    await callFetch(
      process.env.REACT_APP_API_URL +
        'api/sponsor/' +
        this.props.sid +
        '/sponsorship/download/statement/' +
        this.props.year,
      'GET',
      request,
      bearer,
      true,
      'AOC Reports ' + this.props.year
    );
  }

  render() {
    const reports = this.state.reports;
    const classes = this.props.pclasses;

    return (
      <Box className="report-year" mb={3}>
        <Box mb={1}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={6}>
              <h4 style={{ color: 'black' }}>{this.props.year}</h4>
            </Grid>
            <Grid hidden={this.state.show === 0} item xs={6}>
              <Box display="flex" justifyContent="end">
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  variant="contained"
                  onClick={() => {
                    this.downloadReport();
                  }}
                >
                  Download
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <div hidden={this.state.show === 1} style={{ color: 'rgba(0,0,0,1)' }}>
          No report found
        </div>
        <div hidden={this.state.show === 0}>
          <Hidden smUp>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ background: 'rgba(68,12,18,0.25)' }}>
                  <TableRow>
                    <TableCell style={{ color: 'rgba(0,0,0,1)' }}>
                      Report Results
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((report, index) => (
                    <TableRow
                      key={report.studentRef}
                      style={
                        index % 2 === 0
                          ? {}
                          : { background: 'rgba(247,148,30,0.05)' }
                      }
                    >
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <span>Student Name : </span>
                          <span>
                            {report.firstname} {report.lastname}
                          </span>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <span>Student Ref : </span>
                          <span>{report.studentRef}</span>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <span>Payment : </span>
                          <span>{numberFormat(report.paymentAmount)}</span>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
          <Hidden xsDown>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ background: 'rgba(68,12,18,0.25)' }}>
                  <TableRow>
                    <TableCell style={{ color: 'rgba(0,0,0,1)' }}>
                      Student Name
                    </TableCell>
                    <TableCell style={{ color: 'rgba(0,0,0,1)' }}>
                      Student Ref
                    </TableCell>
                    <TableCell align="right" style={{ color: 'rgba(0,0,0,1)' }}>
                      Payment
                    </TableCell>
                    {/* <TableCell align="center">
                  <Button
                    color="primary"
                    fullWidth
                    size="small"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      this.downloadReport();
                    }}
                  >
                    Download
                  </Button>
                </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((report, index) => (
                    <TableRow
                      key={report.studentRef}
                      style={
                        index % 2 === 0
                          ? {}
                          : { background: 'rgba(247,148,30,0.05)' }
                      }
                    >
                      <TableCell>
                        {report.firstname} {report.lastname}
                      </TableCell>
                      <TableCell>{report.studentRef}</TableCell>
                      <TableCell align="right">
                        {numberFormat(report.paymentAmount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
        </div>
      </Box>
    );
  }
}

export default YearlyReports;
