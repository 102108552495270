import React from 'react';
import { slide as Menu } from 'react-burger-menu';

import { Box, Link } from '@material-ui/core';
import PhotoProfile from 'src/components/PhotoProfile';
import { callFetch, getBearer } from '../../helper';
import Cookies from 'universal-cookie';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useNavigate } from 'react-router-dom';

var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '28px',
    height: '20px',
    top: '22px',
    right: '25px'
  },
  bmBurgerBars: {
    background: 'white'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    height: '100%',
    marginTop: '-35px',
    maxWidth: '414px'
  },
  bmMenu: {
    background: 'white',
    height: '200%',
    fontSize: '1.15em',
    marginTop: '0'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  }
};

var menuItem = {
  color: '#f7941e',
  borderRadius: '5px',
  padding: '15px',
  marginLeft: '20px',
  marginTop: '10px',
  cursor: 'pointer'
};

var icon = {
  width: '40%',
  marginBottom: '-8px',
  fontSize: '30px'
};

function menuItemHover(x) {
  x.target.style.color = '#440c12';
  x.target.style.textDecoration = 'underline';
}

function menuItemLeave(x) {
  x.target.style.color = '#f7941e';
  x.target.style.textDecoration = 'none';
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      sponsor: [],
      isLogin: false,
      menuOpen: false
    };
  }

  async isLogin() {
    //const navigate = this.props.navigate;
    const bearer = await getBearer();
    const cookies = new Cookies();
    let loginkey = cookies.get('loginkey');
    let request = {};
    request['loginkey'] = loginkey;
    const validation = await callFetch(
      process.env.REACT_APP_API_URL + 'api/user/islogin',
      'POST',
      request,
      bearer
    );
    if (validation.success === 1) {
      this.setState({
        sponsor: validation.sponsor,
        isLogin: true
      });
    }
  }

  componentDidMount() {
    this.isLogin();
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    const navigate = this.props.nagivate;
    if (this.state.isLogin) {
      return (
        <Menu
          noOverlay
          isOpen={this.state.menuOpen}
          onStateChange={state => this.handleStateChange(state)}
          styles={styles}
          right
          width={'100%'}
          height={'100%'}
        >
          <Box
            mt={2}
            style={{
              textAlign: 'center',
              height: '200px',
              width: '100%',
              color: 'black'
            }}
          >
            <PhotoProfile
              style={{
                width: '150px',
                marginTop: '0px',
                marginBottom: '0px',
                color: 'black',
                marginLeft: '10px'
              }}
            />
            <p style={{ padding: '10px' }}>{this.state.sponsor.firstname}</p>
            <p>{this.state.sponsor.email}</p>
          </Box>
          <Link
            style={menuItem}
            onClick={() => {
              navigate('/dashboard', { replace: true });
              this.closeMenu();
            }}
            title="Go to dashboard page"
            onMouseEnter={event => menuItemHover(event)}
            onMouseLeave={event => menuItemLeave(event)}
          >
            <DashboardIcon style={icon} />
            Dashboard
          </Link>
          <Link
            style={menuItem}
            onClick={() => {
              navigate('/invoices', { replace: true });
              this.closeMenu();
            }}
            title="Go to invoice page"
            onMouseEnter={event => menuItemHover(event)}
            onMouseLeave={event => menuItemLeave(event)}
          >
            <ReceiptIcon style={icon} />
            Invoices
          </Link>
          <Link
            style={menuItem}
            onClick={() => {
              navigate('/reports', { replace: true });
              this.closeMenu();
            }}
            title="Go to reports page"
            onMouseEnter={event => menuItemHover(event)}
            onMouseLeave={event => menuItemLeave(event)}
          >
            <AssessmentIcon style={icon} />
            Reports
          </Link>
          <Link
            style={menuItem}
            onClick={() => {
              navigate('/logout', { replace: true });
              this.closeMenu();
            }}
            title="Go to login page"
            onMouseEnter={event => menuItemHover(event)}
            onMouseLeave={event => menuItemLeave(event)}
          >
            <ExitToAppIcon style={icon} />
            Logout
          </Link>
        </Menu>
      );
    } else {
      return <p> </p>;
    }
  }
}

export default props => {
  const nagivate = useNavigate();
  return <Sidebar nagivate={nagivate} />;
};
