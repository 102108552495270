import React from 'react';
import { callFetch, getBearer } from '../../../helper';

class PaymentToDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sid: this.props.sid,
      year: this.props.year,
      toDate: 0,
      show: 0
    };

    this.getToDate();
  }

  async getToDate() {
    const bearer = await getBearer();

    let request = {};
    const toDate = await callFetch(
      process.env.REACT_APP_API_URL +
        'api/sponsor/' +
        this.props.sid +
        '/sponsorship/payment/' +
        this.props.year.toLowerCase() +
        '/todate',
      'GET',
      request,
      bearer
    );

    this.setState({
      toDate: toDate
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.getToDate();
    }
  }

  render() {
    return <span>{this.state.toDate}</span>;
  }
}

export default PaymentToDate;
